<template>
  <div class="environmentWrap">
    <img :src="environment" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      environment: require("../assets/environment.png"),
    };
  },
};
</script>

<style lang='scss' scoped>
.environmentWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  img {
    width: 100%;
    max-height: 100%;
  }
}
</style>